<template>
  <div class="provider-orders-page">
    <div class="page-title d-flex py-2 justify-content-between">
      <h1 class="h4 mb-0">Перечень заказов поставщику</h1>
    </div>

    <TableFilter
      :columns="fields"
      uniqueKey="table-1"
      @update-selected-columns="columns = $event"
      @search="filter = $event"
      @clear="clearFilterData"
      @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
    />

    <DefaultTable
      :data="orders"
      :fields="fieldsFiltered"
      :filter="filter"
      :loading="loading"
      rowLink="/provider/purchase-orders"
      emptyText="Нет заказов"
      :noLocalSorting="true"
      @sort-change="sorting"
      @pagination-change="paginationChange"
      @per-page-change="perPageChange"
    />

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updateDataWithFilters"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";

function initialFilterData() {
  return {
    number: "",
    order_date: ["", ""],
    contract_stage: "",
    contract_number: "",
    contract_date: ["", ""],
    organization_id: "",
  };
}

function initialIncludeData() {
  return {
    // include: "base_positions,actual_positions",
  };
}

export default {
  name: "ProviderOrders",
  components: {
    TableFilter,
    DefaultTable,
    FilterSidebar,
  },
  computed: {
    ...mapState({
      orders: (state) => state.providerOrders.providerOrders,
    }),
    fieldsFiltered() {
      let res = [];
      this.fields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.fields;
    },
  },
  data: () => ({
    columns: [],
    filterData: initialFilterData(),
    includeData: initialIncludeData(),
    fields: [
      {
        key: "number",
        sortable: true,
        label: "№",
        full: "Номер заказа",
        openDefault: true,
      },
      {
        key: "order_date",
        sortable: true,
        label: "Дата",
        full: "Дата",
        openDefault: true,
      },
      {
        key: "contract_stage",
        sortable: true,
        label: "Этапы",
        full: "Этапы",
        openDefault: true,
      },
      {
        key: "contract_number",
        sortable: true,
        label: "№ договора",
        full: "№ договора",
        openDefault: true,
      },
      {
        key: "contract_date",
        sortable: true,
        label: "Дата договора",
        full: "Дата договора",
        openDefault: true,
      },
      {
        key: "provider",
        nesting: "provider_contr_agent_id",
        sortable: true,
        label: "Поставщик",
        full: "Поставщик",
        openDefault: true,
      },
      {
        key: "organization",
        nesting: "organization_id",
        sortable: true,
        label: "Филиал",
        full: "Филиал",
        openDefault: true,
      },
      {
        key: "responsible_full_name",
        sortable: true,
        label: "Ответственный",
        full: "Ответственный",
        openDefault: true,
      },
      {
        key: "responsible_phone",
        sortable: true,
        label: "Телефон",
        full: "Телефон",
        openDefault: true,
      },
      {
        // key: "comment",
        key: "organization_comment",
        sortable: true,
        label: "Комм.",
        full: "Комментарий",
        openDefault: false,
      },
    ],
    filter: "",
    loading: false,
    breadcrumbs: [
      { title: "Главная", link: "/" },
      { title: "Заказ СК", link: "/" },
      { title: "Перечень заказов поставщику" },
    ],
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),
  methods: {
    async getData() {
      this.loading = true;
      await this.$store.dispatch("getProviderOrders", {
        ...this.includeData,
      });
      this.loading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.getData();
    },
    async updateDataWithFilters(clearSort) {
      this.loading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getProviderOrders", {
        ...this.includeData,
        ...this.filterData,
        ...this.sortingData,
      });
      this.loading = false;
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateDataWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updateDataWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updateDataWithFilters();
    },
  },
  mounted() {
    this.getData();
    this.fields.forEach((item) => {
      if (item.openDefault) {
        this.columns.push(item.label);
      }
    });
  },
};
</script>

<style lang="sass" scoped></style>
